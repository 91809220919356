import { lazy, memo, Suspense } from "react"
import { Navigate, Route, RouteObject, Routes } from "react-router"
import { AuthenticationGuard } from "@/components/AuthenticationGuard"
import { LoadingScreen } from "@/components/LoadingScreen"
import { LoadingSpinner } from "@/components/LoadingSpinner"
import { Layout } from "@/layouts"
import { AuthRoutes, PageRoutes } from "@/utils/constants"

// Lazy-load pages
const HomePage = lazy(() => import("@/pages/HomePage"))
const OrdersPage = lazy(() => import("@/pages/OrdersPage"))
const OrderDetailsPage = lazy(() => import("@/pages/OrderDetailsPage"))
const CredentialsPage = lazy(() => import("@/pages/CredentialsPage"))
const SignupWithInvitePage = lazy(() => import("@/pages/SignupWithInvitePage"))
const MerchantsPage = lazy(() => import("@/pages/MerchantsPage"))
const MerchantDetailsPage = lazy(() => import("@/pages/MerchantDetailsPage"))
const MerchantForm = lazy(() => import("@/pages/MerchantForm"))

// Route mapping (protected routes)
const routes: RouteObject[] = [
    { path: PageRoutes.HOME, element: <HomePage /> },
    { path: PageRoutes.ORDERS, element: <OrdersPage /> },
    { path: `${PageRoutes.ORDERS}/:orderCode`, element: <OrderDetailsPage /> },
    { path: PageRoutes.CREDENTIALS, element: <CredentialsPage /> },
    { path: PageRoutes.MERCHANTS, element: <MerchantsPage /> },
    { path: `${PageRoutes.MERCHANTS}/:merchantCode`, element: <MerchantDetailsPage /> },
    // Handle both onboarding and edit flows in MerchantForm
    { path: `${PageRoutes.MERCHANTS}/*`, element: <MerchantForm /> },
]

export const defaultRoute = PageRoutes.HOME

export const Router = memo(() => (
    // Use React.Suspense to lazy-load components, and show a loading message while loading
    <Suspense fallback={<LoadingScreen />}>
        <Routes>
            {/* Public routes */}
            <Route path={AuthRoutes.LOGIN} element={<SignupWithInvitePage />} />
            <Route path={AuthRoutes.CALLBACK} element={<LoadingSpinner />} />
            <Route path={AuthRoutes.LOGOUT} element={<Navigate to={"/"} replace />} />
            <Route path={"/"} element={<AuthenticationGuard component={Layout} />}>
                {/* Protected routes */}
                {routes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
                <Route index element={<Navigate to={defaultRoute} replace />} />
            </Route>
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to={defaultRoute} replace />} />
        </Routes>
    </Suspense>
))
